export default {
  welcome: "Chào mừng",
  hotels: "Khách sạn",
  resorts: "Khu nghỉ dưỡng",
  place: "Địa điểm",
  places: "Địa điểm",
  liked: "Đã thích",
  comments: "Bình luận",
  save_to_read_list: "Lưu vào danh sách đọc",
  reviews: "Đánh giá",
  share: "Chia sẻ",
  close: "Đóng",
  next: "Tiếp theo",
  prev: "Trước đó",
  report: "Báo cáo lạm dụng",
  comment: {
    report: "Báo cáo lạm dụng",
    form_title: "Ấn tượng của bạn về",
    edit_title: "Chỉnh sửa bình luận",
    delete_title: "Xóa bình luận",
    delete_prompt:
      "Bạn có chắc chắn muốn xóa bình luận này? Hành động này không thể hoàn tác.",
    report_msg:
      "Vui lòng cung cấp thông tin hoặc ngữ cảnh giúp chúng tôi hiểu và xử lý chính xác vấn đề bạn gặp phải.",
  },
  edit: "Chỉnh sửa",
  reply: "Trả lời",
  delete: "Xóa",
  submit: "Gửi",
  cancel: "Hủy",
  message: "Tin nhắn",
  what_before: "Những gì cần biết trước khi đến {place}",
  humidity: "Độ ẩm",
  temperature: "Nhiệt độ",
  km_away: "Cách {distance} km",
  m_away: "Cách {distance} mét",
  all_stay: "Tất cả chỗ nghỉ",
  hotel: "Khách sạn",
  resort: "Khu nghỉ dưỡng",
  home: "Trang chủ",
  contact_us: "Liên hệ chúng tôi",
  contact_des:
    "Gửi tin nhắn cho chúng tôi và chúng tôi sẽ liên hệ lại với bạn sớm nhất có thể",
  form: {
    fullname: "Họ và tên",
    number_phone: "Số điện thoại",
    phone: "Điện thoại",
    subject: "Chủ đề",
    message: "Tin nhắn",
    send: "Gửi tin nhắn",
    thank: "Cảm ơn bạn đã liên hệ với chúng tôi",
  },
  address: "Địa chỉ",
  best_of_attraction: "Những điểm thu hút tốt nhất của Việt Nam",
  travel_to: "Du lịch đến {place}",
  archived: "Đã lưu trữ",
  dashboard: "Bảng điều khiển",
  profile: "Hồ sơ",
  sign_out: "Đăng xuất",
  sign_in: "Đăng nhập",
  see_details: "Xem chi tiết",
  remove: "Xóa",
  privacy: "Chính sách quyền riêng tư",
  terms_of_conditions: "Điều khoản và điều kiện",
  filter: {
    recent: "Mới nhất",
    viewed: "Xem nhiều nhất",
    comments: "Nhiều bình luận nhất",
    Descending: "Giảm dần",
    Ascending: "Tăng dần",
  },
  discover_other_topics: "Khám phá các chủ đề khác",
  discover_other_tags: "Khám phá các tags khác nhau",
  articles: "Bài viết",
  min_read: "{min} phút đọc",
  meta: {
    blog: {
      title: "Chinh phục những địa danh tuyệt vời tại Việt Nam",
      description:
        "Bắt đầu một cuộc phiêu lưu qua những địa điểm du lịch của Việt Nam, mỗi nơi đều mang đến những trải nghiệm độc đáo và những cảnh đẹp hùng vĩ.",
      keywords:
        "Du lịch Việt Nam, Điểm đến kỳ lạ, Phiêu lưu tại Việt Nam, Trải nghiệm độc đáo, Cảnh đẹp ngoạn mục, Khám phá Việt Nam, Những địa điểm cần đến, Những bí ẩn tại Việt Nam, Điểm du lịch nổi bật tại Việt Nam, Hành trình khó quên",
    },
    place: {
      thumbnail:
        "Khám phá những nơi không thể bỏ qua đến khi ghé thăm Việt Nam",
      excerpt:
        "Bắt đầu một cuộc phiêu lưu cuốn hút qua bức tranh phong phú về địa danh tại Việt Nam, hứa hẹn một bản giao hưởng của văn hóa, lịch sử và vẻ đẹp tự nhiên. Từ những con đường hối hả của thành phố sôi động đến những cảnh đẹp thanh bình kể lên những câu chuyện về truyền thống và bản sắc văn hóa.",
      title: "Hướng dẫn về địa điểm quan trọng và đặc biệt",
      description:
        "Mê mẩn bởi vẻ đẹp quyến rũ của Việt Nam với danh sách được chọn lọc về những địa điểm cần ghé thăm",
      keywords:
        "Du lịch Việt Nam, Những điểm đến hàng đầu, Di tích văn hóa, Những địa điểm cần đến, Điểm thu hút Việt Nam, Trải nghiệm du lịch kỳ lạ, Khám phá Việt Nam, Hành trình khó quên, Những điểm kỳ diệu của Việt Nam",
    },
    attraction: {
      thumbnail:
        "Khám phá những điểm thu hút du lịch và nghỉ dưỡng hàng đầu tại Việt Nam",
      excerpt:
        "Đắm chìm vào trái tim của Việt Nam với danh sách về những điểm thu hút du lịch và nghỉ dưỡng hàng đầu. Mỗi địa điểm là một câu chuyện độc đáo, từ những câu chuyện cổ xưa được khắc sâu trong các di tích lịch sử đến những cảnh đẹp ngoạn mục thể hiện vẻ đẹp tự nhiên của đất nước.",
      title:
        "Khám phá những điểm thu hút du lịch và nghỉ dưỡng hàng đầu tại Việt Nam",
      description:
        "Khám phá danh sách được chọn lọc về những điểm thu hút du lịch độc đáo và cảnh đẹp ngoạn mục tại Việt Nam. Trải nghiệm vẻ đẹp của thiên nhiên, văn hóa và lịch sử trong đất nước sống động này.",
      keywords:
        "Các điểm thu hút ở Việt Nam, điểm du lịch, kỳ quan tự nhiên, di tích văn hóa, những địa điểm cần đến, điểm đến du lịch, du lịch Việt Nam, di tích lịch sử, vẻ đẹp ngoạn mục",
    },
    author: {
      title: "Những bài viết du lịch tuyệt vời từ {author}",
      description:
        "Chia sẻ những hành trình du lịch tuyệt vời cùng với {author}. Khám phá những địa điểm đẹp và lắng nghe những câu chuyện hấp dẫn từ những người đã tham gia vào những cuộc phiêu lưu đáng nhớ.",
    },
    tag_title: "Khám phá tất cả bài viết được đánh dấu bằng {tag}",
    home: {
      title: "Khám phá những điểm đến du lịch tuyệt vời tại Việt Nam",
      description:
        "Khám phá những kỳ quan của Việt Nam với AttractionsVietnam. Từ những bãi biển tuyệt vời đến những khu chợ náo nhiệt, trải nghiệm bản sắc văn hóa của đất nước tuyệt vời này.",
      keywords:
        "Du lịch Việt Nam, điểm đến du lịch, khách sạn Việt Nam, khu nghỉ dưỡng Việt Nam, những điểm du lịch độc đáo, chia sẻ trải nghiệm du lịch",
    },
    sign_in: {
      title: "Đăng Nhập - AttractionsVietnam",
      description:
        "Đăng nhập vào AttractionsVietnam để trải nghiệm những điểm đến du lịch tuyệt vời tại Việt Nam. Quản lý hồ sơ của bạn và chia sẻ những trải nghiệm của bạn.",
    },
    sign_up: {
      title: "Tham Gia với Chúng Tôi - AttractionsVietnam",
      description:
        "Tham gia AttractionsVietnam để truy cập những tính năng độc đáo. Tạo tài khoản của bạn, quản lý thông tin cá nhân và trở thành một phần của cộng đồng du lịch sôi động của chúng tôi.",
    },
    contact: {
      title: "Liên Hệ - Attractions Vietnam",
      description:
        "Chúng tôi ở đây để hỗ trợ bạn với mọi thắc mắc và yêu cầu liên quan đến du lịch và khách sạn tại Việt Nam. Hãy để chúng tôi giúp bạn tạo ra một trải nghiệm du lịch tuyệt vời và thoải mái.",
    },
    hotels: {
      thumbnail: "Danh Sách Khách Sạn",
      title: "Danh Sách Khách Sạn - Khám Phá Sang Trọng và Độc Đáo",
      description:
        "Bước vào thế giới sang trọng và độc đáo của các khách sạn tại Việt Nam. Danh sách được biên soạn theo thứ tự chữ cái này hứa hẹn mang lại trải nghiệm khó quên tại những điểm đến hàng đầu.",
      keywords:
        "Khách sạn Việt Nam, Trải nghiệm sang trọng, Danh sách khách sạn độc đáo, Đặt phòng khách sạn, Du lịch Việt Nam",
    },
    resorts: {
      thumbnail: "Danh Sách Khu Nghỉ Dưỡng",
      title:
        "Danh Sách Khu Nghỉ Dưỡng - Khám Phá Khu Nghỉ Dưỡng Sang Trọng và Spa Chăm Sóc Sức Khỏe",
      description:
        "Khám phá danh sách các khu nghỉ dưỡng nổi tiếng tại Việt Nam, nơi sự sang trọng và đẳng cấp hội tụ, mang đến cho bạn trải nghiệm kỳ nghỉ không giới hạn.",
      keywords:
        "Khu nghỉ dưỡng Việt Nam, Danh sách khu nghỉ dưỡng, Kỳ nghỉ sang trọng, Đặt phòng khu nghỉ dưỡng, Kỳ nghỉ du lịch",
    },
  },
  type_places: {
    commute: "Di chuyển",
    tourist_attraction: "Địa điểm du lịch",
    restaurant: "Nhà hàng",
    cafe: "Quán cà phê",
    parking: "Bãi đỗ xe",
    bus: "Bến xe buýt",
    park: "Công viên",
    shopping: "Mua sắm",
    hospital: "Bệnh viện",
  },
  view_all: "Xem tất cả",
  topic: "Chủ đề",
  insert: "Chèn",
  upload: "Tải lên",
  upload_image: "Tải hình ảnh",
  upload_file: "Tải tệp",
  image: "Hình ảnh",
  article_title: "Tiêu đề bài viết",
  article_excerpt: "Trích đoạn bài viết",
  article_content: "Nội dung bài viết",
  category: "Danh mục",
  tags: "Tags",
  featured_image: "Hình ảnh nổi bật",
  validate: {
    character_long: "Nội dung phải có ít nhất {length} ký tự",
    thumbnail: "Vui lòng tải lên hình ảnh nổi bật cho bài viết của bạn.",
  },
  next_step: "Bước tiếp theo",
  author: "Tác giả",
  sign_up: "Đăng ký",
  or: "HOẶC",
  already_account: "Đã có tài khoản?",
  forgot_password: "Quên mật khẩu",
  forgot_your_password: "Quên mật khẩu của bạn",
  dont_have_account: "Chưa có tài khoản?",
  continue: "Tiếp tục",
  email_address: "Địa chỉ email",
  go_back_for: "Quay lại để",
  term_auth:
    "Bằng cách đăng ký hoặc đăng nhập, bạn hiểu và đồng ý với điều khoản sử dụng và chính sách quyền riêng tư của chúng tôi.",
  what_looking: "Bạn đang tìm kiếm điều gì?",
  attractions: "Địa điểm thu hút",
  language: "Ngôn ngữ",
  dark_mode: "Chế độ tối",
  explore: "Khám phá",
  homepage: {
    top_attractions: "Điểm đến phổ biến",
    discover_attractions: "Khám phá hơn 100+ điểm đến thu hút",
    new_stays: "Bạn đang tìm chỗ nghỉ lý tưởng?",
    discover_stays:
      "Những chỗ nghỉ được đặt nhiều nhất bởi những vị khách giống như bạn ",
    recent_articles: "Bài viết gần đây",
    discover_articles:
      "Nhận những cập nhật và hiểu biết mới nhất về địa điểm, khách sạn, khu nghỉ dưỡng và điểm thu hút cũng như tìm hiểu bản sắc văn hóa dân tộc tại Việt Nam.",
    join_newsletter: "Tham gia bản tin của chúng tôi",

    read_and_share: "Đăng ký và nhận những ưu đãi du lịch tốt nhất!",
    sign_1:
      "Khám phá và đăng ký những ưu đãi du lịch tốt nhất trên khắp Việt Nam",
    sign_2: "Hướng dẫn chi tiết cho chuyến du lịch đến Việt Nam",
    register_discount: "Đăng ký để nhận tin tức và khuyến mãi từ chúng tôi",
    please_read: "Vui lòng đọc ",
    enter_email: "Nhập địa chỉ email của bạn",
    hero_title: "Những ưu đãi du lịch tốt nhất từ mọi nơi",
    hero_des:
      "Hãy thử tìm kiếm một thành phố, một khách sạn cụ thể, hoặc thậm chí là một điểm danh lam thắng cảnh",
  },
  other_topics: "Các chủ đề khác",
  other_tags: "Các nhãn khác",
  k_hotels: "Khách sạn",
  k_resorts: "Khu nghỉ dưỡng",
  trending_topic: "Chủ đề HOT",
  popular_articles: "Bài viết phổ biến",
  more_tags: "Tags",
  more_tag: "Tags",
  notepage_des:
    "Tìm hiểu về thời tiết, tôn trọng phong tục địa phương, đóng gói đồ cần thiết và tận hưởng những hương vị mới để có một trải nghiệm du lịch đáng nhớ.",
  note: "Ghi chú",
  locale_weather: "Thời tiết địa phương",
  show_all_photos: "Hiển thị tất cả ảnh",
  what_to_know: "Cần chuẩn bị gì?",
  destinations_near: "Địa điểm gần {place}",
  destinations_blissfull:
    "Khám phá những địa điểm du lịch gần {place} cho một kỳ nghỉ tuyệt vời",
  see_all_destinations: "Xem tất cả địa điểm",
  popular_stays_in: "Chỗ nghỉ phổ biến tại {place}",
  discover_offers: "Khám phá ưu đãi, đề xuất du lịch và hoạt động tại {place}",
  explore_all: "Khám phá tất cả địa điểm",
  search: "Tìm kiếm",
  socials: "Mạng xã hội",
  guest_reviews: "Đánh giá từ khách hàng",
  posted_on: "Đăng trên",
  facilities_of: "Tiện nghi - {hotel}",
  see_less_amenities: "Xem ít tiện nghi hơn",
  see_more_amenities: "Xem thêm tiện nghi",
  location_nearby: "Vị trí và địa điểm gần đó",
  nearby_commit:
    "Dữ liệu chỉ mang tính chất tham khảo. Để biết thông tin chi tiết hơn, vui lòng tham khảo",
  add_discussion: "Thảo luận",
  add_reply: "Thêm phản hồi",
  all_rights_reserved: "Bản quyền đã được đăng ký",
  legal_notice: "Thông báo pháp lý",
  guest_about_place: "Những gì mọi người nói về khách sạn {place}",
  cheap_price_place: "Thời gian rẻ nhất để nghỉ tại {place}",
  price_place_description:
    "Trong {months}, bạn có thể ở tại {place} với giá trung bình <b>US${price}</b> mỗi đêm",
  faq_hotel: "Câu hỏi thường gặp về khách sạn tại {place}",
  month: {
    Jan: "Tháng một",
    Feb: "Tháng hai",
    Mar: "Tháng ba",
    Apr: "Tháng tư",
    May: "Tháng năm",
    Jun: "Tháng sáu",
    Jul: "Tháng bảy",
    Aug: "Tháng tám",
    Sep: "Tháng chín",
    Oct: "Tháng mười",
    Nov: "Tháng mười một",
    Dec: "Tháng mười hai",
  },
  score_brokendown: {
    staff: "Nhân viên",
    services: "Dịch vụ",
    clean: "Sạch sẽ",
    comfort: "Tiện nghi",
    value: "Giá trị",
    location: "Vị trí",
    breakfast: "Bữa sáng",
    free_wifi: "Wifi miễn phí",
    paid_wifi: "Wifi trả phí",
  },
  room_guests: "{guests} khách tối đa, trong đó có tối đa {persons} người lớn.",
  room_type: "Loại phòng",
  number_guests: "Số khách",
  guests: "Khách",
  show_details: "Xem Chi tiết",
  showing: "Hiển thị",
  next_page: "Trang kế tiếp",
  prev_page: "Trang trước",
  traveller_type: "Nhóm du khách",
  verified_reviews: "Đánh giá đã được xác minh",
  book_now: "Đặt ngay",
  attraction_in: "Địa điểm thu hút tại {place}",
  visitor_reviews: "Đánh giá của du khách",
  write_review: "Viết đánh giá",
  view_all_review: "Xem tất cả đánh giá",
  showing_of: "Hiển thị {reviews} trên {total}",
  see_all_attractions: "Xem tất cả địa điểm thu hút",
  day: {
    monday: "Thứ Hai",
    tuesday: "Thứ Ba",
    wednesday: "Thứ Tư",
    thursday: "Thứ Năm",
    friday: "Thứ Sáu",
    saturday: "Thứ Bảy",
    sunday: "Chủ Nhật",
  },
  night: "đêm",
  nights: "đêm",
  night_in: "trong",
  calendar: "Lịch",
  i_am_flexible: "Linh hoạt",
  how_long_stay: "Bạn muốn ở bao lâu?",
  when_go: "Khi nào bạn muốn đi?",
  from: "Từ",
  select_up_month: "Có thể chọn tới {month} tháng",
  select_dates: "Chọn ngày",
  adults: "người lớn",
  children: "trẻ em",
  rooms: "phòng",
  age_message:
    "Để tìm cho bạn một chỗ ở phù hợp với toàn bộ nhóm cùng với giá chính xác, chúng tôi cần biết tuổi của trẻ em khi bạn trả phòng.",
  done: "Hoàn tất",
  title_check:
    "Vui lòng chọn ngày nhận phòng/trả phòng để xem giá và tình trạng phòng",
  faq_about: "Câu hỏi thường gặp về {hotel}",
  start_from: "Bắt đầu từ ",
  flex: {
    week: "Một tuần",
    weekend: "Cuối tuần",
    month: "Một tháng",
    other: "Khác",
  },
  change_password: "Đổi mật khẩu",
  new_password: "Mật khẩu mới",
  ideas_accom: "Ý tưởng cho chỗ nghỉ của bạn",
  view_more: "Xem thêm",
  nearby_attractions: "Điểm tham quan gần đây",
  photos: "Hình ảnh",
  search_hotel_around: "Tìm kiếm khách sạn quanh đây",
  by_sign_agree: "Bằng cách đăng nhập, bạn đồng ý với chúng tôi về",
  submit_post: "Gửi bài viết",
  list_articles: "Danh sách bài viết",
  description_dashboard:
    "Xem bảng điều khiển của bạn, quản lý bài viết, đăng ký và chỉnh sửa hồ sơ.",
  view: "Xem",
  edit_profile: "Chỉnh sửa hồ sơ",
  firstname: "Tên",
  lastname: "Họ",
  current_password: "Mật khẩu hiện tại",
  username: "Tên người dùng",
  social: "Mạng xã hội",
  or_drag: "hoặc kéo và thả",
  up_to: "lên đến",
  update: "Cập nhật",
  profile_updated_msg: "Hồ sơ được cập nhật thành công",
  choose_a_username: "Chọn một tên người dùng",
  user_msg:
    "Một tên người dùng giúp bạn tương tác và được nhớ tới. Hãy chọn ngay!",
  name: "Tên",
  action: "Hành động",
  my_account: "Tài khoản ",
  my_post: "Bài viết",
  dark_theme: "Giao diện tối",
  logout: "Đăng xuất",
  status: "Trạng thái",
  inappropriate: "Không phù hợp",
  violence: "Bạo lực",
  spam: "Spam",
  other: "Khác",
  topic_des:
    "Chọn một chủ đề phù hợp với sở thích của bạn và bắt đầu hành trình khám phá của bạn",
  post_popular: "Bài Viết Phổ Biến",
  post_des:
    "Hãy để chúng tôi hướng dẫn bạn đến những điểm đến lý tưởng cho cuộc phiêu lưu của bạn",
  role: "Vai trò",
  pending: "chờ đợi",
  published: "công bố",
  where_do_you_go: "Bạn muốn đến đâu ?",
  recent_place_popular: "Điểm đến phổ biến gần đây",
  business_traveller: "Người đi công tác",
  couple: "Cặp đôi",
  family_with_children: "Gia đình có trẻ em",
  solo_traveller: "Người đi một mình",
  review_category_group_of_friends: "Nhóm bạn",
  order_first: "Cũ nhất trước",
  newest_first: "Mới nhất trước",
  featuredreviews: "Đánh giá nổi bật",
  lowest_scores: "Điểm thấp nhất",
  highest_score: "Điểm cao nhất",
  no_yet_review: "Chưa có đánh giá",
  all_travel: "Tất cả du khách",
  room_facilities: "Tiện nghi trong phòng",
  exact_dates: "Ngày chính xác",
  dayx: "ngày",
  days: "ngày",
  show_me_more: "Hiển thị nhiều hơn",
  author_loaded_full: "Các bài viết của tác giả đều đã được hiển thị.",
  copy_link: "Sao chép liên kết",
  report_this_pge: "Báo cáo trang này",
  your_password: "Mật khẩu của bạn",
  thing_to_do: "Các hoạt động trải nghiệm",
  age_needed: "Độ tuổi",
  year_old: "tuổi",
  discover_tour: "Khám phá",
  nearby: {
    nearby: "Có gì xung quanh",
    dining: "Nhà hàng & quán cà phê",
    publicTransport: "Phương tiện công cộng",
    airports: "Các sân bay gần nhất",
    beaches: "Các bãi biển trong khu vực",
    top: "Điểm tham quan hàng đầu",
    naturalBeauty: "Cảnh đẹp thiên nhiên",
  },
  cafeBars: "Cafe/quán bar",
  restaurants: "Nhà hàng",
  mountain: "Núi",
  river: "Sông",
  train: "Ga",
  bus: "Buýt",
  leave_your_comment: "Để lại bình luận của bạn",
  show_map: "Hiển thị bản đồ",
  translate_to: "Dịch sang {lang}",
  view_original: "Xem bản gốc ",
  related_articles: "Những bài viết liên quan",
  reserve_now: "Đặt trước ngay",
  about_us: "Về chúng tôi",
  about_us_des:
    "Khám phá Du Lịch Việt Nam với AttractionsVietnam - Nền tảng du lịch độc đáo!",
  other_entity_near: "{type} khác gần đây ",
  tourist_attraction_near: "Các điểm du lịch gần đây",
  house_rules: "Nội quy nhà của",
  cancelled_payment: "Hủy/trả trước",
  age_restrict: "Giới hạn độ tuổi",
  accept_payment: "Các phương thức thanh toán được chấp nhận",
  navigation: {
    overview: "Tổng quan",
    rooms: "Phòng",
    location: "Vị trí",
    facilities: "Tiện nghi",
    house_rules: "Nội quy nhà",
    reviews: "Đánh giá",
    details: "Chi tiết",
  },
  check_now: "Kiểm tra ngay",
  show_less: "Hiển thị ít hơn",
  view_facilities: "Xem thêm tiện nghi",
  best_hotel: "Khách sạn tốt nhất",
  best_resort: "Khu nghỉ dưỡng tốt nhất",
  best_attrations: "Các điểm du lịch nổi tiếng",
  suggest_hotel: "Khách sạn được đề xuất",
  star: "Sao",
  distance_legit:
    "Tất cả các khoảng cách được đo bằng đường thẳng. Khoảng cách di chuyển thực tế có thể thay đổi",
  offer: {
    h2: "Khuyến mãi và ưu đãi đặc biệt dành cho bạn",
    title: "Lập kế hoạch cho kỳ nghỉ dài của bạn và bắt đầu khám phá",
    heading:
      "Khám phá các khu vực cung cấp chỗ ở cho kỳ nghỉ dài, với nhiều lựa chọn giá tháng giảm giá.",
    button: "Tìm chỗ nghỉ",
  },
  smoking: "Hút thuốc",
  see_all: "Xem tất cả các cơ sở lưu trú tại ",
  check_availibility: "Kiểm tra khả năng có sẵn",
  form_searching: {
    location: {
      placeholder: "Điểm đến hoặc cơ sở lưu trú",
      des: "Bạn đi đâu?",
    },
    guest: {
      des: "Khách và phòng",
    },
    checkbox: "Tôi đang đi công tác",
    checkbox_tooltips:
      "<span class='block mb-2'>Nếu bạn chọn tùy chọn này, chúng tôi sẽ hiển thị các tiện ích phổ biến cho chuyến công tác</span> <span class='block'>như bữa sáng, Wifi và chỗ đậu xe miễn phí.</span>",
  },
  view_deal: "Xem ưu đãi",
  free_cancel: "Hủy miễn phí",
  no_prepayment: "Không cần thanh toán trước",
  soldout_msg: "Chúng tôi không có phòng nào phù hợp với tìm kiếm của bạn",
  recent_search: "Tìm kiếm gần đây của bạn",
  popular_des: "Các điểm đến phổ biến gần đây",
  sort: {
    popularity: "Lựa chọn hàng đầu",
    upsort_bh: "Nhà và căn hộ trước",
    price: "Giá (thấp nhất trước)",
    bayesian_review_score: "Đánh giá hàng đầu",
    review_score_and_price: "Đánh giá tốt nhất & giá thấp nhất",
    class: "Xếp hạng tài sản (cao đến thấp)",
    class_asc: "Xếp hạng tài sản (thấp đến cao)",
    class_and_price: "Xếp hạng tài sản và giá",
    distance_from_search: "Khoảng cách từ trung tâm thành phố",
  },
  show_price: "Hiển thị giá",
  room_title: "Chọn ngày để xem giá",
  room_options: "Các loại phòng",
  more_details: "Xem chi tiết",
  overall_rating: "Dựa trên đánh giá từ các đối tác của chúng tôi",
  guest_rating_location: "Xếp hạng cơ sở lưu trú của khách cho Vị trí",
  view_on_map: "Xem bản đồ",
  attractions_in: "Điểm tham quan ở ",
  where: "Bạn đi đâu?",
  when: "Thời gian?",
  location: "Vị trí",
  currency: "Tiền tệ",
  checkin: "Nhận phòng",
  checkout: "Trả phòng",
  properties: "{count}  chỗ nghỉ ở {place}",
  great_deal: "Ưu đãi tuyệt vời",
  redirect_des:
    "Chúng tôi đang chuyển bạn đến trang web của đối tác để hoàn tất việc đặt chỗ của bạn.",
  redirect_msg: "Nếu bạn không được tự động chuyển hướng, vui lòng ",
  click_here: "bấm vào đây",
  where_to: "Đến đâu",
  start_trip: "Bắt đầu hành trình của bạn",
  clear_all: "Xóa tất cả",
  sort_by: "Sắp xếp theo",
  sort_: "Phân loại",
  who: "Khách",
  when_trip: "Khi nào bạn đi",
  reset_filters: "Đặt lại bộ lọc",
  finding: "Tìm kiếm",
  filter_: "Phân loại",
  oops_search: "Rất tiếc, chúng tôi không tìm thấy kết quả.",
  oops_des:
    "Chúng tôi không thể tìm thấy bất kỳ khách sạn nào, nhưng đừng lo lắng hãy khám phá các ưu đãi của chúng tôi!",
  region: "Khu vực {place}",
  region_hotel: "Khách sạn ở {place}",
  go_to_homepage: "Quay lại trang chủ",
  place_hotel: {
    title: "Tìm kiếm khách sạn tốt nhất tại {place} - Tìm nơi lưu trú tốt nhất",
    description:
      "Khám phá và đặt phòng khách sạn tốt nhất tại Thành phố Hồ Chí Minh. Từ những khách sạn 5 sao sang trọng đến các chỗ ở giá cả phải chăng, hãy tìm chỗ ở hoàn hảo cho chuyến thăm của bạn đến Sài Gòn. Tận hưởng mức giá tốt nhất và các tiện nghi hàng đầu.",
  },
  our_partner: "Đối Tác Của Chúng Tôi",
  subscribe: "Đăng Ký",
  subscribe_msg: "Đăng ký và chúng tôi sẽ gửi cho bạn những ưu đãi tốt nhất",
  partner_msg:
    "Các dịch vụ và sản phẩm du lịch trên trang web này được cung cấp bởi các đối tác của chúng tôi",
  toc: "Mục lục",
  sub_blog_msg:
    "Nhận thông báo khi chúng tôi xuất bản nội dung mới và những ưu đãi đặc biệt bất cứ lúc nào",
  show_more: "Hiển thị thêm {count}",
  flight_tour: "Tour và Hoạt Động",
  destinations:"Điểm Tham Quan", help:"Giúp đỡ",
  properties_in:"Cơ sở lưu trú tại {place}",
  hcm_city:"Tp Hồ Chí Minh"
};
